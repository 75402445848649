import style from "./Erro404.module.scss";

import { useEffect } from "react";
import { useSelector } from "react-redux";

import { Link } from "react-router-dom";
import { useConteudo } from "../../redux/sliceConteudo";



export function Erro404() {
    const conteudos = useSelector(useConteudo);
    const conteudoErro404 = conteudos[0].erro404;

    useEffect (() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <main>
            <section className={style.erro404}>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12">
                            <h1>{conteudoErro404.banner.bannerTitulo}</h1>
                            <Link to={'/'}>
                                <button>Volta para a home</button>
                            </Link>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    )
}