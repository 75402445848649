import style from "./Quadro.module.scss"

import { Image } from "../Image";

type QuadroProps = {
    foto: string,
    titulo: string,
}

function Quadro({ foto, titulo }: QuadroProps) {
    return (
        <div className={style.quadro}>
            <div className={style.quadro__imgContainer}>
                <Image pathImg={foto} />
            </div>
            <div className={style.quadro__tituloContainer}>
                <h2>{titulo}</h2>
            </div>
        </div>
    )
}

export { Quadro }