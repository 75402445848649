import style from './AvisoCookie.module.scss';

import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import Cookies from 'js-cookie';

function AvisoCookie() {
    const [visibleClass, setVisibleClass] = useState("d-block")

    useEffect (() => {
        const haveCookie: boolean = Cookies.get('acceptCookies') === "true" ? true : false;

        if (haveCookie) {
            setVisibleClass("d-none")
        }
    }, [visibleClass]);
    

    function handleCookies() {
        Cookies.set('acceptCookies', 'true', { expires: 7 })
        setVisibleClass("d-none")
    }
    
    return (
        <div className={`${visibleClass} ${style.avisoCookie}`}>
            <div className="container">
                <div className="row justify-content-center align-items-stretch">
                    <div className="col-11 col-lg-7 col-xl-6 mb-3 mb-lg-0">
                        <p>
                            Este site utiliza cookies para melhorar sua experiência. Ao continuar navegando, você concorda com o uso de cookies. Para conferir, acesse: <Link to={"/politicas-privacidade"}>Políticas de Privacidade</Link>.
                        </p>
                    </div>
                    <div className="col-11 col-lg-3 col-xl-2 d-flex justify-content-lg-end align-items-center">
                        <button onClick={handleCookies}>Aceitar</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export { AvisoCookie }