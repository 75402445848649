import style from './Image.module.scss'

type ImagemBoloProps = {
    pathImg: string,
}

export function Image({ pathImg }: ImagemBoloProps) {
    return (
        <img className={style.imgBolo} src={pathImg} alt="" />
    )
}