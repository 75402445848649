import style from './Modal.module.scss';

type ModalProps = {
    visible: boolean,
    onClose?: () => void
}

function Modal( { visible, onClose }: ModalProps ) {
    const isVisible = visible === false ? "d-none" : "d-block" 
    return (
        <div className={isVisible}>
            <div className={style.modalStyle}>
                <div className={style.modalStyle__quadroModal}>
                    <button  onClick={onClose}>X</button>
                    <p>Preencha corretamente todos os campos do formulário para realizar o envio!</p>
                </div>
            </div>
        </div>
    )
}

export { Modal }