import { BrowserRouter, Route, Routes } from "react-router-dom";

import { Home } from "./pages/Home";
import { Orcamentos } from "./pages/Orcamentos";
import { BolosAluguel } from "./pages/BolosAluguel";
import { BoloAluguel } from "./pages/BolosAluguel/BoloAluguel";
import { Velas } from "./pages/Velas";
import { Vela } from "./pages/Velas/Vela";
import { TermoUso } from "./pages/TermoUso";
import { PoliticasPrivacidade } from "./pages/PoliticasPrivacidade";
import { Erro404 } from "./pages/Erro404";

import { Menu } from "./components/Menu";
import { AvisoCookie } from "./components/AvisoCookie";
import { BtnWpp } from "./components/BtnWpp";
import { Rodape } from "./components/Rodape";

function AppRoutes() {
  return (
    <BrowserRouter>
      <Menu />

      <main>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/orcamentos" element={<Orcamentos />} />
          <Route path="/aluguel" element={<BolosAluguel />} />
          <Route path="/aluguel/:id/*" element={<BoloAluguel />} />
          {/* <Route path="/velas" element={<Velas />} />
          <Route path="/velas/:id/*" element={<Vela />} /> */}
          <Route path="/termos-uso" element={<TermoUso />} />
          <Route path="/politicas-privacidade" element={<PoliticasPrivacidade />} />
          <Route path="*" element={<Erro404 />} />
        </Routes>
      </main>
      <AvisoCookie />
      <BtnWpp />
      <Rodape />
    </BrowserRouter>
  );
}

export { AppRoutes }