import style from "./Descricao.module.scss";
import { IDescricao, IDescricaoVela } from "../../interfaces/Interfaces";

interface QuadroInfosBoloProps {
    nome: string,
    descricao: IDescricao,
    precoAluguel: number,
    linkBtn: string
}
interface QuadroInfosVelaProps {
    nome: string,
    descricao: IDescricaoVela,
    precoAluguel: number,
    linkBtn: string
}

export function DescricaoBolo({nome, descricao, precoAluguel, linkBtn}: QuadroInfosBoloProps) {
    return (
        <div className={style.bolo__quadro}>
            <h1>{nome}</h1>
            <p><span>Descrição:</span> {descricao.texto}</p>
            <p><span>Material:</span> {descricao.material}</p>
            <p><span>Topo:</span> {descricao.topo}</p>
            <p><span>Altura:</span> {descricao.altura}</p>
            <p><span>Diâmetro:</span> {descricao.diametro}</p>
            <p><span>Quantidade de Andares: </span> {descricao.qtdAndar}</p>
            <p><span>Localização: </span> {descricao.localizacao}</p>
            <p className="mb-4 mb-md-5"><span>Locação:</span> R${precoAluguel},00</p>
            <a href={linkBtn} target="_blank">Quero alugar</a>
        </div>
    )
}
export function DescricaoVela({nome, descricao, precoAluguel, linkBtn}: QuadroInfosVelaProps) {
    return (
        <div className={style.bolo__quadro}>
            <h1>{nome}</h1>
            <p><span>Descrição:</span> {descricao.texto}</p>
            <p><span>Material:</span> {descricao.material}</p>
            <p><span>Altura:</span> {descricao.altura}</p>
            <p className="mb-4 mb-md-5"><span>Preço:</span> R${precoAluguel},00</p>
            <a href={linkBtn} target="_blank">Quero comprar</a>
        </div>
    )
}