import { createSlice } from "@reduxjs/toolkit";

import conteudo from "../data/conteudo.json";
import { IConteudo } from "../interfaces/Interfaces";

const initialState: IConteudo[] = conteudo;

const sliceConteudo = createSlice({
  name: "conteudo",
  initialState,
  reducers: {},
});

export default sliceConteudo.reducer;

export const useConteudo = (state: any) => {
  return state.conteudo as IConteudo[];
};