import style from './DefaultTitle.module.scss';

type DefaultTitleProps = {
    children: string,
    typeTitle: "h1" | "h2" | "h3",
    icon?: boolean,
    uppercase?: boolean,
    center?: boolean,
    color?: "rosa" | "branco",
}

function DefaultTitle({ children, typeTitle, icon, uppercase, center, color = "rosa" }: DefaultTitleProps) {
    const textUppercase: string = uppercase ? "text-uppercase" : ""
    const textCenter: string = center ? "text-center" : ""

    let colorStyle: string = ""
    if (color === "rosa") {
        colorStyle = "text-rosa"
    } else if (color === "branco") {
        colorStyle = "text-branco"
    }

    switch (typeTitle) {
        case "h1":
            return <h1 className={`${style.titleStyle} ${textUppercase} ${colorStyle} ${textCenter}`}>
                        {children}
                        {icon && <img src="assets/img/coracao-claro.png" alt="Coração" />}
                    </h1>
        case "h2":
            return <h2 className={`${style.titleStyle} ${textUppercase} ${colorStyle} ${textCenter}`}>
                        {children}
                        {icon && <img src="assets/img/coracao-claro.png" alt="Coração" />}
                    </h2>
        case "h3":
            return <h3 className={`${style.titleStyle} ${textUppercase} ${colorStyle} ${textCenter}`}>
                        {children}
                        {icon && <img src="assets/img/coracao-claro.png" alt="Coração" />}
                    </h3>
    }
}

export { DefaultTitle }