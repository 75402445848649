import style from './TermoUso.module.scss';

import { useEffect } from 'react';

import { useConteudo } from '../../redux/sliceConteudo';
import { useSelector } from 'react-redux';

import { DefaultTitle } from '../../components/DefaultTitle';

function TermoUso() {
    const conteudos = useSelector(useConteudo);
    const conteudoTermos = conteudos[0].termosUso

    useEffect (() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <section className={style.termoUso}>
            <div className="container">
                <div className="row justify-content-center align-items-center mb-5">
                    <div className="col-11">
                        <DefaultTitle
                            typeTitle='h1'
                            center
                        >{conteudoTermos.banner.bannerTitulo}</DefaultTitle>
                    </div>
                </div>
                <div className="row justify-content-center align-items-center mb-4">
                    <div className="col-11">
                        <h2>1. Retirada e Devolução</h2>
                        <p>A retirada dos bolos pode ser feita de segunda a sexta, das 9h às 18h, no ateliê ou com entrega pela Coração d’Isopor mediante pagamento de frete.</p>
                        <p>Para bolos usados às quintas-feiras, a retirada deve ocorrer na quarta-feira e a devolução na sexta-feira da mesma semana. Para os bolos de fim de semana, a retirada é na sexta-feira e a devolução na segunda-feira.</p>
                    </div>
                </div>
                <div className="row justify-content-center align-items-center mb-4">
                    <div className="col-11">
                        <h2>2. Conservação</h2>
                        <p>Os bolos devem ser devolvidos nas mesmas condições de limpeza, conservação e com a mesma embalagem em que foram recebidos.</p>
                        <p>Não é permitido o uso de velas ou qualquer objeto que possa causar danos, como furos ou colas. Alterações no bolo só são permitidas com aprovação prévia.</p>
                    </div>
                </div>
                <div className="row justify-content-center align-items-center mb-4">
                    <div className="col-11">
                        <h2>3. Renovação do Aluguel</h2>
                        <p>O aluguel não será renovado automaticamente após o término. Para continuar com o serviço, é necessário realizar um novo pedido.</p>
                        <p>Não é permitido transferir, sublocar ou emprestar os bolos para terceiros.</p>
                    </div>
                </div>
                <div className="row justify-content-center align-items-center mb-4">
                    <div className="col-11">
                        <h2>4. Pagamento</h2>
                        <p>O pagamento deve ser feito integralmente para garantir a reserva da data.</p>
                        <p>Aceitamos pagamento via Pix ou parcelamento em até 3x sem juros.</p>
                    </div>
                </div>
                <div className="row justify-content-center align-items-center">
                    <div className="col-11">
                        <h2>5. Atraso ou Danos</h2>
                        <p>O não cumprimento do prazo de devolução acarretará uma multa de 20% do valor do aluguel <strong>por dia</strong> de atraso.</p>
                        <p>Os bolos devem ser devolvidos na embalagem original. Em caso de dano ou perda, será cobrada uma taxa para substituição.</p>
                    </div>
                </div>
            </div>
        </section>
    )
}

export { TermoUso }