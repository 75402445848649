import style from "./Home.module.scss";

import { useEffect } from "react";

import { useSelector } from "react-redux";
import { useConteudo } from "../../redux/sliceConteudo";

import { BannerHome } from "../../components/Banner";
import { DefaultTitle } from "../../components/DefaultTitle";
import { DefaultText } from "../../components/DefaultText";
import { GradientBar } from "../../components/GradientBar";
import { QuadroLink } from "../../components/QuadroLink";



export function Home() {
    const conteudos = useSelector(useConteudo);
    const conteudoHome = conteudos[0].home

    useEffect (() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <BannerHome />
            <GradientBar />
            <section className={style.home}>
                <div className="container">
                    <div className="row justify-content-center align-items-stretch">
                        <div className="col-11 col-md-7 col-lg-6 col-xl-5 mb-3 mb-md-0 d-flex flex-column justify-content-center">
                            <DefaultTitle
                                typeTitle="h1"
                                icon
                            >{conteudoHome.sectionSobre.sectionSobreTitulo}</DefaultTitle>
                            <DefaultText>{conteudoHome.sectionSobre.sectionSobreTexto}</DefaultText>
                        </div>
                        <div className="col-11 col-md-5 col-lg-6 offset-xl-1 col-xl-5 d-flex justify-content-center align-items-center">
                            <iframe
                                src={conteudoHome.sectionSobre.sectionSobreLinkVideo}
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                                title="Video institucional Coração d`Isopor"
                            />
                        </div>
                    </div>
                    <div className={`row justify-content-center align-items-center ${style.home__mCustom}`}>
                        <div className="col-11 mb-4">
                            <DefaultTitle
                                typeTitle="h2"
                                center
                            >{conteudoHome.sectionHub.sectionHubTitulo}</DefaultTitle>
                            <DefaultText
                                center
                            >
                                {conteudoHome.sectionHub.sectionHubSubTitulo}
                            </DefaultText>
                        </div>
                        <div className="col-11 col-md-4 col-xl-3 mb-3 mb-md-0">
                            <QuadroLink
                                url="/orcamentos"
                                titulo={conteudoHome.sectionHub.sectionHubBtn1}
                                bg="bgRosaClaro"
                            />
                        </div>
                        {/* ADICIONAR offset-xl-1 quando habilitar velas */}
                        <div className="col-11 col-md-4 col-xl-3 mb-3 mb-md-0">
                            <QuadroLink
                                url="/aluguel"
                                titulo={conteudoHome.sectionHub.sectionHubBtn2}
                                bg="bgRosaEscuro"
                            />
                        </div>
                        {/* <div className="col-11 col-md-4 offset-xl-1 col-xl-3">
                            <QuadroLink
                                url="/velas"
                                titulo={conteudoHome.sectionHub.sectionHubBtn3}
                                bg="bgVerde"
                            />
                        </div> */}
                    </div>
                </div>
            </section>
        </>
    )
}