import { configureStore } from "@reduxjs/toolkit";

import sliceBolosAluguel from "./sliceBolosAluguel";
import sliceVelas from "./sliceVelas";
import sliceConteudo from "./sliceConteudo";

const store = configureStore({
  reducer: {
    bolosAluguel: sliceBolosAluguel,
    velas: sliceVelas,
    conteudo: sliceConteudo,
  },
});

export { store };
