import style from "./BolosAluguel.module.scss"

import { useEffect } from "react";
import { Link } from "react-router-dom";

import { useSelector } from "react-redux";
import { useBolosAluguel } from "../../redux/sliceBolosAluguel";
import { useConteudo } from "../../redux/sliceConteudo";

import { BannerInternas } from "../../components/Banner";
import { Quadro } from "../../components/Quadro";
import { GradientBar } from "../../components/GradientBar";

function BolosAluguel() {

    const bolosAluguel = useSelector(useBolosAluguel);
    const conteudos = useSelector(useConteudo);

    const conteudoAluguelBolos = conteudos[0].aluguel

    useEffect (() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <BannerInternas
                titulo={conteudoAluguelBolos.banner.bannerTitulo}
            />
            <GradientBar />
            <section className={style.bolosList}>
                <div className="container">
                    <div className="row justify-content-center justify-content-sm-start justify-content-lg-start align-items-stretch">
                        {bolosAluguel.map((bolo, index) => {
                            return (
                                <div className={`col-11 col-md-6 col-lg-4 col-xxl-3 mb-4 ${style.bolosList__colQuadro}`} key={index}>
                                    <Link className="d-block" key={bolo.id} to={`/aluguel/${[bolo.urlBolo]}`}>
                                        <Quadro
                                            foto={bolo.urlImg.principal}
                                            titulo={bolo.nome}
                                        />
                                    </Link>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </section>
        </>
    );
}

export { BolosAluguel }