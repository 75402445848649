import style from './Banner.module.scss'

import logo from '../../assets/logo-escuro.png';
import { DefaultTitle } from '../DefaultTitle';
import { DefaultText } from '../DefaultText';

function BannerHome() {
    return (
        <section className={style.bannerHome}>
            <video
                src="assets/img/home/bannerHome.mp4"
                autoPlay
                muted
                loop
                playsInline
                onContextMenu={() => false}
                preload="auto"
            />
        </section>
    )
}

type BannerInternasProps = {
    titulo: string,
    texto1?: string,
    texto2?: string,
}

function BannerInternas({ titulo, texto1, texto2 }: BannerInternasProps) {
    const isMb = texto1 || texto2 ? "mb-3" : ""
    return (
        <section className={style.bannerInterna}>
            <img src="assets/img/be-banner.png" alt="Urso Bê Coração d'Isopor" />
            <div className={`container ${style.bannerInterna__containerStyle}`}>
                <div className={`row justify-content-center align-items-center ${isMb}`}>
                    <div className="col-11 col-sm-10 col-md-8 col-lg-7 col-xl-6">
                        <DefaultTitle
                            typeTitle='h1'
                            uppercase
                            center
                        >
                            {titulo}
                        </DefaultTitle>
                    </div>
                </div>
                {texto1 &&
                    <div className="row justify-content-center align-items-center">
                        <div className="col-11 col-sm-10 col-md-8 col-lg-7 col-xl-6">
                            <DefaultText
                                branco
                                center
                            >
                                {texto1}
                            </DefaultText>
                        </div>
                    </div>
                }
                {texto2 &&
                    <div className="row justify-content-center align-items-center">
                        <div className="col-11 col-sm-10 col-md-8 col-lg-7 col-xl-6">
                            <DefaultText
                                branco
                                center
                            >
                                {texto2}
                            </DefaultText>
                        </div>
                    </div>
                }
            </div>
        </section >
    )
}


type PropsBannerPadrao = {
    titulo?: string;
}

export function BannerPadrao({ titulo }: PropsBannerPadrao) {
    return (
        <section className={style.bannerPadrao}>
            <div className={style.bannerPadrao__filtro}></div>
            <div className={`container ${style.bannerPadrao__containerCustom}`}>
                <div className="row justify-content-center align-items-center">
                    <div className="col-11 text-center">
                        <h1>{titulo}</h1>
                        <img src={logo} alt="" />
                    </div>
                </div>
            </div>
        </section>
    )
}


export { BannerHome, BannerInternas }