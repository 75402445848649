import style from './Orcamentos.module.scss';

import { useEffect, useState } from 'react';

import { IMaskInput } from "react-imask";

import { useSelector } from 'react-redux';
import { useConteudo } from '../../redux/sliceConteudo';

import { BannerInternas } from '../../components/Banner';
import { Modal } from '../../components/Modal';
import { GradientBar } from '../../components/GradientBar';

function Orcamentos() {
    const conteudos = useSelector(useConteudo);
    const conteudoOrcamento = conteudos[0].orcamento

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [nomeCompleto, setNomeCompleto] = useState('')
    const [dataEvento, setDataEvento] = useState('')
    const [cepEvento, setCepEvento] = useState('')
    const [temaBolo, setTemaBolo] = useState('')
    const [quantidadeAndares, setQuantidadeAndares] = useState('')
    const [coresPrincipais, setCoresPrincipais] = useState('')
    const [obsBolo, setObsBolo] = useState('')

    const [ modal, setModal] = useState(false)


    const urlWpp: string = `https://wa.me/5511993810758?text=Olá,+gostaria+de+um+orçamento+com+as+informações+abaixo!%0a%0aNome+Completo:+${nomeCompleto}%0aData+do+Evento:+${dataEvento}%0aCEP+do+local+do+evento:+${cepEvento}%0aTema+do+bolo:+${temaBolo}%0aQuantidade+de+andares:+${quantidadeAndares}%0aCores+do+bolo:+${coresPrincipais}%0aDetalhes+especiais:+${obsBolo}`

    function sendForm() {
        const checkNomeCompleto: boolean = nomeCompleto.length >= 4 ? true : false
        const checkDataEvento: boolean = dataEvento.length >= 10 ? true : false
        const checkCepEvento: boolean = cepEvento.length >= 9 ? true : false
        const checkTemaBolo: boolean = temaBolo.length >= 4 ? true : false
        const checkQuantidadeAndares: boolean = quantidadeAndares.length >= 2 ? true : false
        const checkCoresPrincipais: boolean = coresPrincipais.length >= 4 ? true : false
        const checkObsBolo: boolean = obsBolo.length >= 3 ? true : false

        if (checkNomeCompleto &&
            checkDataEvento &&
            checkCepEvento &&
            checkTemaBolo &&
            checkQuantidadeAndares &&
            checkCoresPrincipais &&
            checkObsBolo) {
            window.open(urlWpp, '_blank');
        } else {
            setModal(true)
        }
    }

    function handleNome(e: React.ChangeEvent<HTMLInputElement>) {
        setNomeCompleto(e.target.value)
    }

    function handleDataEvento(e: React.ChangeEvent<HTMLInputElement>) {
        setDataEvento(e.target.value)
    }

    function handleCepEvento(e: React.ChangeEvent<HTMLInputElement>) {
        setCepEvento(e.target.value)
    }

    function handleTemaBolo(e: React.ChangeEvent<HTMLInputElement>) {
        setTemaBolo(e.target.value)
    }

    function handleQuantidadeAndares(e: React.ChangeEvent<HTMLSelectElement>) {
        setQuantidadeAndares(e.target.value)
    }

    function handleCoresPrincipais(e: React.ChangeEvent<HTMLInputElement>) {
        setCoresPrincipais(e.target.value)
    }

    function handleObsBolo(e: React.ChangeEvent<HTMLInputElement>) {
        setObsBolo(e.target.value)
    }

    return (
        <>
            <BannerInternas
                titulo={conteudoOrcamento.banner.bannerTitulo}
            />
            <GradientBar />
            <section className={style.descOrcamento}>
                <div className="container">
                    <div className="row justify-content-center align-items-center">
                        <div className="col-11 col-md-10 col-lg-8 col-xl-6 d-flex flex-column gap-3">
                            <p>{conteudoOrcamento.banner.bannerTexto1}</p>
                            <p>{conteudoOrcamento.banner.bannerTexto2}</p>
                        </div>
                    </div>
                </div>
            </section>
            <section className={style.formOrcamento}>
                <div className="container">
                    <div className="row justify-content-center align-items-center">
                        <div className="col-11 col-md-10 col-lg-8 col-xl-6">
                            <div className="d-flex flex-column gap-3">
                                <div className="d-flex justify-content-center align-items-center">
                                    <input
                                        className="w-100"
                                        type="text"
                                        name="nomeCompleto"
                                        id="nomeCompleto"
                                        placeholder="Nome completo:"
                                        onChange={handleNome}
                                    />
                                </div>
                                <div className="d-flex justify-content-center align-items-center flex-column flex-md-row gap-3">
                                    <IMaskInput
                                        mask="00/00/0000"
                                        className="w-100"
                                        type="text"
                                        name="dataEvento"
                                        id="dataEvento"
                                        placeholder="Data do evento:"
                                        onChange={handleDataEvento}
                                    />
                                    <IMaskInput
                                        mask="00000-000"
                                        className="w-100"
                                        type="text"
                                        name="cepEvento"
                                        id="cepEvento"
                                        placeholder="CEP do evento:"
                                        onChange={handleCepEvento}
                                    />
                                </div>
                                <div className="d-flex justify-content-center align-items-center">
                                    <input
                                        className="w-100"
                                        type="text"
                                        name="temaBolo"
                                        id="temaBolo"
                                        placeholder="Tema do bolo:"
                                        onChange={handleTemaBolo}
                                    />
                                </div>
                                <div className="d-flex justify-content-center align-items-center">
                                    <select
                                        className="w-100"
                                        name="quantidadeAndares"
                                        id="quantidadeAndares"
                                        onChange={handleQuantidadeAndares}
                                    >
                                        <option disabled selected>Quantidade de andares:</option>
                                        <option value="2A">2 Andares</option>
                                        <option value="2AM">2 Andares e Meio</option>
                                        <option value="3A">3 Andares</option>
                                        <option value="4A">4 Andares</option>
                                        <option value="5A">5 Andares</option>
                                    </select>
                                </div>
                                <div className="d-flex justify-content-center align-items-center">
                                    <input
                                        className="w-100"
                                        type="text"
                                        name="coresPrincipais"
                                        id="coresPrincipais"
                                        placeholder="Cores principais do bolo:"
                                        onChange={handleCoresPrincipais}
                                    />
                                </div>
                                <div className="d-flex justify-content-center align-items-center">
                                    <input
                                        className="w-100"
                                        type="text"
                                        name="obsBolo"
                                        id="obsBolo"
                                        placeholder="Deseja troca de personagens, iluminação ou movimento no bolo?"
                                        onChange={handleObsBolo}
                                    />
                                </div>
                                <div className="d-flex justify-content-center align-items-center">
                                    <button onClick={sendForm}>Enviar</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Modal visible={modal} onClose={() => setModal(false)} />
        </>
    )
}

export { Orcamentos }