import style from "./Rodape.module.scss";

import logo from "../../assets/logo-branco.png";

import { useSelector } from "react-redux";
import { useConteudo } from "../../redux/sliceConteudo";
import { Link } from "react-router-dom";

export function Rodape() {
    const conteudos = useSelector(useConteudo);
    const conteudoRodape = conteudos[0].rodape

    return (
        <footer className={style.footer}>
            <div className="container">
                <div className="row justify-content-center align-items-center">
                    <div className="col-11 col-md-2 col-lg-3 col-xl-4 col-xxl-3 d-flex justify-content-center justify-content-md-start algin-items-center mb-2 mb-md-3">
                        <img src={logo} alt="Logo Coração d'Isopor" />
                    </div>
                    <div className="col-11 col-md-10 col-lg-9 col-xl-8 col-xxl-7 d-flex justify-content-center justify-content-md-end align-items-center mb-3 mb-md-3">
                        <ul>
                            {conteudoRodape.map((item, index) => (
                                <li key={index}>
                                    <Link to={item.url}>{item.nome}</Link>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
                <div className="row justify-content-center align-items-center">
                    <div className="col-11 col-md-12 col-xxl-10">
                        <p className="text-center text-lg-start">© 2024 Coração D'Isopor. Todos os direitos reservados.</p>
                    </div>
                </div>
            </div>
        </footer>
    )
}