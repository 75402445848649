import { createSlice } from "@reduxjs/toolkit";

import { IBolos } from "../interfaces/Interfaces";

import bolosAluguel from "../data/bolosAluguel.json";

const initialState: IBolos[] = bolosAluguel;

const sliceBolosAluguel = createSlice({
  name: "bolosAluguel",
  initialState,
  reducers: {},
});

export default sliceBolosAluguel.reducer;

export const useBolosAluguel = (state: any) => {
  return state.bolosAluguel as IBolos[];
};

/* 
  Modelo link wpp

  11 99381-0758

  Olá, gostaria de saber as datas disponíveis para o aluguel do bolo X.
*/