import { DefaultTitle } from '../DefaultTitle';
import style from './QuadroLink.module.scss';

import { Link } from 'react-router-dom';

type QuadroLinkProps = {
    url: string,
    titulo: string,
    bg: "bgRosaClaro" | "bgRosaEscuro" | "bgVerde",
}

function QuadroLink( {url, titulo, bg}: QuadroLinkProps ) {

    switch(bg) {
        case "bgRosaClaro":
            return (
                <Link to={url}>
                    <div className={`${style.quadroLink} ${style.bgRosaClaro}`}>
                        <DefaultTitle
                            typeTitle="h3"
                            color="branco"
                        >{titulo}</DefaultTitle>
                    </div>
                </Link>
            )
        case "bgRosaEscuro":
            return (
                <Link to={url}>
                    <div className={`${style.quadroLink} ${style.bgRosaEscuro}`}>
                        <DefaultTitle
                            typeTitle="h3"
                            color="branco"
                        >{titulo}</DefaultTitle>
                    </div>
                </Link>
            )
        case "bgVerde":
            return (
                <Link to={url}>
                    <div className={`${style.quadroLink} ${style.bgVerde}`}>
                        <DefaultTitle
                            typeTitle="h3"
                            color="branco"
                        >{titulo}</DefaultTitle>
                    </div>
                </Link>
            )

    }
}

export { QuadroLink }