import style from './PoliticasPrivacidade.module.scss';

import { useEffect } from 'react';

import { useSelector } from 'react-redux';
import { useConteudo } from '../../redux/sliceConteudo';

import { DefaultTitle } from '../../components/DefaultTitle';

function PoliticasPrivacidade() {
    const conteudos = useSelector(useConteudo);
    const conteudoPoliticas = conteudos[0].politicasPrivacidade

    useEffect (() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <section className={style.politicasPrivacidade}>
            <div className="container">
                <div className="row justify-content-center align-items-center mb-5">
                    <div className="col-11">
                        <DefaultTitle
                            typeTitle='h1'
                            center
                        >{conteudoPoliticas.banner.bannerTitulo}</DefaultTitle>

                    </div>
                </div>
                <div className="row justify-content-center align-items-center mb-4">
                    <div className="col-11">
                        <p>Sua privacidade é importante para nós. Esta Política de Privacidade descreve como coletamos, utilizamos e protegemos suas informações ao acessar nosso site de listagem de produtos para aluguel, venda e solicitação de orçamento de projeto, desenvolvido com React e TypeScript. O contato com nossa empresa é feito exclusivamente por meio de links para o WhatsApp.</p>
                    </div>
                </div>
                <div className="row justify-content-center align-items-center mb-4">
                    <div className="col-11">
                        <h2>1. Coleta de Informações</h2>
                        <p className="mb-2">Nosso site coleta informações de forma automática ou fornecida diretamente pelo usuário, conforme abaixo:</p>
                        <ul>
                            <li><strong>Informações fornecidas por você:</strong> Ao acessar nossos links de WhatsApp para solicitar informações, realizar um orçamento ou consultar produtos para aluguel e venda, você poderá nos fornecer dados como nome, telefone e informações sobre o produto ou serviço de interesse.</li>
                            <li><strong>Informações automáticas:</strong> Nosso site pode coletar dados de navegação, como endereço IP, tipo de navegador, páginas acessadas e tempo de permanência em cada página. Esses dados são utilizados apenas para melhorar a experiência do usuário.</li>
                        </ul>
                    </div>
                </div>
                <div className="row justify-content-center align-items-center mb-4">
                    <div className="col-11">
                        <h2>2. Uso de Informações</h2>
                        <p className="mb-2">As informações coletadas são utilizadas para:</p>
                        <ul>
                            <li>Entrar em contato e responder a solicitações enviadas pelo WhatsApp;</li>
                            <li>Enviar cotações ou propostas de aluguel e venda;</li>
                            <li>Melhorar nossos serviços e funcionalidades do site;</li>
                            <li>Cumprir obrigações legais ou regulatórias.</li>
                        </ul>
                    </div>
                </div>
                <div className="row justify-content-center align-items-center mb-4">
                    <div className="col-11">
                        <h2>3. Compartilhamento de Informações</h2>
                        <p className="mb-2">Não compartilhamos suas informações pessoais com terceiros, exceto quando necessário para:</p>
                        <ul>
                            <li>Cumprir exigências legais ou ordens judiciais;</li>
                            <li>Proteger nossos direitos, propriedade ou segurança, ou os direitos de nossos usuários.</li>
                        </ul>
                    </div>
                </div>
                <div className="row justify-content-center align-items-center mb-4">
                    <div className="col-11">
                        <h2>4. Proteção de Dados</h2>
                        <p>Adotamos medidas de segurança apropriadas para proteger suas informações pessoais contra acessos não autorizados, alterações, divulgação ou destruição. Entretanto, é importante lembrar que nenhuma transmissão de dados pela internet é completamente segura.</p>
                    </div>
                </div>
                <div className="row justify-content-center align-items-center mb-4">
                    <div className="col-11">
                        <h2>5. Cookies e Tecnologias Similares</h2>
                        <p>Utilizamos cookies para melhorar a experiência de navegação no site e entender como os usuários interagem com nossas páginas. Cookies são pequenos arquivos de texto que ficam armazenados em seu navegador. Ao continuar a navegar em nosso site, você concorda com o uso de cookies.</p>
                    </div>
                </div>
                <div className="row justify-content-center align-items-center mb-4">
                    <div className="col-11">
                        <h2>6. Links para Terceiros</h2>
                        <p>Nosso site contém links para plataformas de terceiros, como o WhatsApp. Não nos responsabilizamos pela privacidade e segurança dessas plataformas. Recomendamos que você leia as políticas de privacidade desses serviços.</p>
                    </div>
                </div>
                <div className="row justify-content-center align-items-center mb-4">
                    <div className="col-11">
                        <h2>7. Direitos do Usuário</h2>
                        <p>Você tem o direito de acessar, corrigir ou solicitar a exclusão de suas informações pessoais. Para exercer esses direitos, entre em contato diretamente conosco pelo WhatsApp.</p>
                    </div>
                </div>
                <div className="row justify-content-center align-items-center mb-4">
                    <div className="col-11">
                        <h2>8. Alterações nesta Política</h2>
                        <p>Reservamo-nos o direito de modificar esta Política de Privacidade a qualquer momento. As alterações entrarão em vigor assim que publicadas no site. Recomendamos que você revise esta página periodicamente para se manter atualizado sobre nossas práticas de privacidade.</p>
                    </div>
                </div>
                <div className="row justify-content-center align-items-center">
                    <div className="col-11">
                        <h2>9. Contato</h2>
                        <p className="mb-2">Caso tenha qualquer dúvida ou solicitação em relação à nossa Política de Privacidade, entre em contato conosco através do link para o WhatsApp disponível no site.</p>
                        <p>Esse texto aborda os principais pontos para garantir a proteção tanto da sua empresa quanto dos usuários, estando alinhado com boas práticas de privacidade e proteção de dados.</p>
                    </div>
                </div>
            </div>
        </section>
    )
}

export { PoliticasPrivacidade }