import style from './Text.module.scss';

type DefaultTextProps = {
    children: string
    branco?: boolean,
    center?: boolean,
}

function DefaultText( { children, branco, center }: DefaultTextProps ) {
    const textoBranco = branco ? "text-branco" : "text-preto"
    const textoAlinhamento = center ? "text-center" : ""

    return (
        <p className={`${style.textStyle} ${textoBranco} ${textoAlinhamento}`}>{children}</p>
    )
}

export { DefaultText }