import style from "./Menu.module.scss";

import { Link } from "react-router-dom";

import { useSelector } from "react-redux";
import { useConteudo } from "../../redux/sliceConteudo";

import logo from "../../assets/logo-branco.png";


export function Menu() {
    const conteudos = useSelector(useConteudo);
    const conteudoMenu = conteudos[0].menu

    return (
        <header className={style.header}>
            <div className={style.header__logo}>
                <div className="container">
                    <div className="row justify-content-center align-items-center">
                        <Link to="/">
                            <img src={logo} alt="" />
                        </Link>
                    </div>
                </div>
            </div>
            <div className={style.header__itensMenu}>
                <div className="container">
                    <div className="row justify-content-center align-items-center">
                        <ul>
                            {conteudoMenu.map((item, index) =>(
                                <li key={index}>
                                    <Link to={item.url}>
                                        {item.nome}
                                    </Link>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </header>
    )
}