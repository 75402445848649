import { createSlice } from "@reduxjs/toolkit";
import { IVelas } from "../interfaces/Interfaces";
import velas from "../data/velas.json";

const initialState: IVelas[] = velas;

const sliceVelas = createSlice({
  name: "velas",
  initialState,
  reducers: {},
});

export default sliceVelas.reducer;

export const useVelas = (state: any) => {
  return state.velas as IVelas[];
};

/* 
  Modelo link wpp

  11 99381-0758

  Olá, gostaria de comprar a vela X.
*/