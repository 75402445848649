import style from "./Carousel.module.scss";

import { IImgs } from "../../interfaces/Interfaces";

import { Image } from "../Image";


type ImgsProps = {
    urlImg: IImgs;
}

export function Carousel({ urlImg }: ImgsProps) {
    return (
        <div id="carousel-bolo" className="carousel slide" data-bs-ride="carousel">
            <div className={`carousel-inner ${style.bolo__containerSlides}`}>
                <div className="carousel-item active">
                    <Image pathImg={`../../${urlImg.principal}`} />
                </div>
                {urlImg.detalhada1 &&
                    <div className="carousel-item">
                        <Image pathImg={`../../${urlImg.detalhada1}`} />
                    </div>
                }
                {urlImg.detalhada2 &&
                    <div className="carousel-item">
                        <Image pathImg={`../../${urlImg.detalhada2}`} />
                    </div>
                }
            </div>
            <div className={`carousel-indicators ${style.bullets}`}>
                {urlImg.detalhada1 &&
                    <>
                        <button type="button" data-bs-target="#carousel-bolo" data-bs-slide-to="0" className={`active ${style.bolo__containerDots}`} aria-current="true"></button>
                        <button type="button" data-bs-target="#carousel-bolo" data-bs-slide-to="1" className={style.bolo__containerDots}></button>
                        {urlImg.detalhada2 &&
                            <button type="button" data-bs-target="#carousel-bolo" data-bs-slide-to="2" className={style.bolo__containerDots}></button>
                        }
                    </>
                }
            </div>
        </div>
    )
}